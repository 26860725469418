<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'invest-companies-index' }" />

    <template v-if="investCompany">
      <h2 class="mb-4">{{ investCompany.name }}</h2>

      <v-sheet elevation="1">
        <v-tabs background-color="deep-purple secondary" center-active dark hide-slider>
          <v-tab>Обзор</v-tab>

          <v-tab>Синонимы</v-tab>

          <v-tab-item>
            <invest-company-summary
              :invest-company="investCompany"
              :updating="updating"
              @update="updateInvestCompany"
            />
          </v-tab-item>

          <v-tab-item>
            <invest-company-synonyms :key="updatingInvestName" :invest-company="investCompany" />
          </v-tab-item>
        </v-tabs>
      </v-sheet>
    </template>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import InvestCompanySummary from "~/components/invest-companies/id/summary.vue";
import InvestCompanySynonyms from "~/components/invest-companies/id/synonyms.vue";

export default {
  data() {
    return {
      investCompany: null,

      loading: true,
      updating: false,
      updatingInvestName: 0
    };
  },
  created() {
    this.fetchInvestCompany();
  },
  computed: {
    investCompanyId() {
      return this.$route.params.id;
    }
  },
  methods: {
    async fetchInvestCompany() {
      this.loading = true;

      try {
        this.investCompany = await this.$axios.$get(`investment-companies/${this.investCompanyId}`);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не получена информация по инвест-дому: "${this.investCompanyId}"` + html);
      } finally {
        this.loading = false;
      }
    },
    async updateInvestCompany(payload) {
      this.updating = true;

      const isChange =
        payload?.name !== this.investCompany?.name || payload.forRuStocks !== this.investCompany?.forRuStocks;

      const nameInvestCompany = this.investCompany?.name;

      if (!isChange) {
        this.updating = false;

        return;
      }

      try {
        this.investCompany = await this.$axios.$patch(
          `investment-companies/${this.investCompany.id}`,
          JSON.stringify(payload),
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );

        if (this.investCompany.name !== nameInvestCompany) {
          this.updatingInvestName++;
        }

        this.$snackbar.success("Инвест-дом обновлен");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалось обновить инвест-дом: " + html);
      } finally {
        this.updating = false;
      }
    }
  },
  components: {
    InvestCompanySummary,
    InvestCompanySynonyms
  }
};
</script>
