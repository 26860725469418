<template>
  <div class="pa-3">
    <v-row class="props-list">
      <v-col md="2">Тикер</v-col>
      <v-col md="10">
        <v-text-field
          :value="investCompanyDto.name"
          label="Название"
          @input="changeField({ field: 'name', value: $event })"
        />
      </v-col>

      <v-col md="12">
        <v-checkbox v-model="forRuStocks" label="Для русских акций" />
      </v-col>
    </v-row>

    <v-divider class="my-3" />

    <div class="text-right">
      <v-btn color="accent" :loading="updating" @click="handleSave">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
import { editorForm } from "~/mixins/multi-editor/editor-form";

import { UpdateInvestCompanyDto } from "~/libs/experts/update-invest-company-dto";

export default {
  mixins: [editorForm],
  props: {
    investCompany: {
      type: Object,
      required: true
    },
    updating: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      investCompanyDto: { ...this.investCompany }
    };
  },
  computed: {
    forRuStocks: {
      get() {
        return this.investCompanyDto.forRuStocks;
      },
      set(value) {
        this.changeField({ field: "forRuStocks", value });
      }
    }
  },
  methods: {
    async handleSave() {
      this.$emit("update", new UpdateInvestCompanyDto(this.investCompanyDto));
    },
    changeField(payload) {
      this.commitChange(this.investCompanyDto, payload);
    }
  }
};
</script>
