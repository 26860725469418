<template>
  <div class="pa-3">
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">Синоним</th>
          <th class="text-left">Действие</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!synonyms.length">
          <td class="text-center" colspan="2">Синонимы отсутствуют</td>
        </tr>

        <template v-else>
          <tr v-for="(synonym, index) of synonyms" :key="index">
            <td>
              <v-text-field v-model="synonym.name" />
            </td>

            <td>
              <v-btn icon color="error" @click="deleteByIndex(index)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>

    <div class="mt-4 text-right">
      <v-btn color="primary mr-4" @click="add">Новый синоним</v-btn>

      <v-btn color="accent" :loading="updating" @click="save">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

export default {
  props: {
    investCompany: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      updating: false,

      synonyms: []
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        const newSynonyms = await this.$axios.$get(`investment-companies/${this.investCompany.id}/synonyms`);

        this.synonyms = [...newSynonyms]?.map(synonym => {
          return { name: synonym };
        });
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не получены синонимы по id: "${this.investCompany.id}"` + html);
      }
    },
    async save() {
      if (this.updating) return;

      this.updating = true;

      try {
        const synonyms = this.synonyms.map(synonym => synonym.name);

        const newSynonyms = await this.$axios.$put(`investment-companies/${this.investCompany.id}/synonyms`, {
          synonyms
        });

        this.synonyms = [...newSynonyms]?.map(synonym => {
          return { name: synonym };
        });

        this.$snackbar.success("Синонимы обновлены");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не обновлено:" + html);
      } finally {
        this.updating = false;
      }
    },

    add() {
      this.synonyms.push({ name: "" });
    },
    deleteByIndex(index) {
      this.synonyms.splice(index, 1);
    }
  }
};
</script>
